import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChineseNewYear from './pages/ChineseNewYear';
import TX2years from './pages/TX2years';
import ToTheMoon from './pages/ToTheMoon';
import SportsDay from './pages/SportsDay';
import NY2024 from './pages/NY2024';

function App(): JSX.Element {
  return (
    <div>
      <Routes>
        <Route path="/" element={<NY2024 />} />
      </Routes>
    </div>
  );
}

export default App;
