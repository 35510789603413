/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import sunburst from './images/sunburst.svg';
import settings from './images/settings.svg';
import ConfettiImg from './images/confetti.svg';
import close from './images/close.svg';
// import logo from './images/logo.svg';
import logo from './images/Logo-Event.png';
import spin from './images/spin.svg';
import spinHover from './images/spin-hover.svg';
import arrowSpin from './images/element-arrow.svg';
import lanternRight from './images/BG-botton.png';
import lanternRightSmall from './images/BG-Top.png';

import techx from './images/techx.svg';
import confetti from 'canvas-confetti';
import Slot from '../../Slot';
import SoundEffects from '../../SoundEffects';
import './style.css';

function TX2years(): JSX.Element {
  const [remain, setRemain] = useState<any>(null);
  const [popup, setPopup] = useState(false);
  const [numberGroupWinner, setNumberGroupWinner] = useState(0);
  const [winner, setWinner] = useState<any>(null);
  const numberGroupWinnerRef: any = useRef(null);
  useEffect(() => {
    const drawButton = document.getElementById('draw-button') as HTMLButtonElement | null;
    const drawButtonHover = document.getElementById(
      'draw-button-hover'
    ) as HTMLButtonElement | null;
    const settingsButton = document.getElementById('settings-button') as HTMLButtonElement | null;
    const settingsWrapper = document.getElementById('settings') as HTMLDivElement | null;
    const settingsContent = document.getElementById('settings-panel') as HTMLDivElement | null;
    const settingsSaveButton = document.getElementById('settings-save') as HTMLButtonElement | null;
    const settingsCloseButton = document.getElementById(
      'settings-close'
    ) as HTMLButtonElement | null;
    const sunburstSvg = document.getElementById('sunburst') as HTMLImageElement | null;
    const confettiCanvas = document.getElementById('confetti-canvas') as HTMLCanvasElement | null;
    const nameListTextArea = document.getElementById('name-list') as HTMLTextAreaElement | null;
    const removeNameFromListCheckbox = document.getElementById(
      'remove-from-list'
    ) as HTMLInputElement | null;
    const enableSoundCheckbox = document.getElementById('enable-sound') as HTMLInputElement | null;

    // Graceful exit if necessary elements are not found
    if (
      !(
        drawButton &&
        drawButtonHover &&
        settingsButton &&
        settingsWrapper &&
        settingsContent &&
        settingsSaveButton &&
        settingsCloseButton &&
        sunburstSvg &&
        confettiCanvas &&
        nameListTextArea &&
        removeNameFromListCheckbox &&
        enableSoundCheckbox
      )
    ) {
      console.error('One or more Element ID is invalid. This is possibly a bug.');
      return;
    }

    if (!(confettiCanvas instanceof HTMLCanvasElement)) {
      console.error('Confetti canvas is not an instance of Canvas. This is possibly a bug.');
      return;
    }
    const soundEffects = new SoundEffects();
    const MAX_REEL_ITEMS = 24;
    const CONFETTI_COLORS = ['#F7E494', '#8433cc', '#faff07', '#00c4f3', '#fa0c0c'];
    let confettiAnimationId: number;

    /** Confeetti animation instance */
    const customConfetti = confetti.create(confettiCanvas, {
      resize: true,
      useWorker: true,
    });

    /** Triggers cconfeetti animation until animation is canceled */
    const confettiAnimation = (): void => {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.getElementsByTagName('body')[0].clientWidth;
      const confettiScale = Math.max(0.5, Math.min(1, windowWidth / 1100));

      customConfetti({
        particleCount: 10,
        gravity: 0.8,
        spread: 90,
        origin: { y: 0.6 },
        colors: [CONFETTI_COLORS[Math.floor(Math.random() * CONFETTI_COLORS.length)]],
        scalar: confettiScale,
      });

      confettiAnimationId = window.requestAnimationFrame(confettiAnimation);
    };

    /** Function to stop the winning animation */
    const stopWinningAnimation = (): void => {
      if (confettiAnimationId) {
        window.cancelAnimationFrame(confettiAnimationId);
      }
      sunburstSvg.style.display = 'none';
    };

    /**  Function to be trigger before spinning */
    const onSpinStart = (): void => {
      stopWinningAnimation();
      drawButton.disabled = true;
      drawButtonHover.disabled = true;
      settingsButton.disabled = true;
      soundEffects.spin((MAX_REEL_ITEMS - 1) / 10);
    };

    /**  Functions to be trigger after spinning */
    const onSpinEnd = async (): Promise<void> => {
      confettiAnimation();
      setPopup(true);
      sunburstSvg.style.display = 'block';
      await soundEffects.win();
      drawButton.disabled = false;
      drawButtonHover.disabled = false;
      settingsButton.disabled = false;
    };

    /**  Function to be trigger before spinning */

    /** Slot instance */
    const slot = new Slot({
      reelContainerSelector: '#reel',
      maxReelItems: MAX_REEL_ITEMS,
      onSpinStart,
      onSpinEnd,
      onNameListChanged: stopWinningAnimation,
      onSetWinner: (winners: string[]) => setWinner(winners),
      onSetRemain: (remainNum: number) => setRemain(remainNum),
    });

    /** To open the setting page */
    const onSettingsOpen = (): void => {
      nameListTextArea.value = slot.names.length ? slot.names.join('\n') : '';
      removeNameFromListCheckbox.checked = slot.shouldRemoveWinnerFromNameList;
      enableSoundCheckbox.checked = !soundEffects.mute;
      settingsWrapper.style.display = 'block';
    };

    /** To close the setting page */
    const onSettingsClose = (): void => {
      settingsContent.scrollTop = 0;
      settingsWrapper.style.display = 'none';
    };

    // Click handler for "Draw" button
    drawButton.addEventListener('click', () => {
      if (!slot.names.length) {
        onSettingsOpen();
        return;
      }
      slot.spin();
    });

    drawButtonHover.addEventListener('click', () => {
      if (!slot.names.length) {
        onSettingsOpen();
        return;
      }
      slot.spin();
    });

    // Hide fullscreen button when it is not supported
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - for older browsers support

    // Click handler for "Settings" button
    settingsButton.addEventListener('click', onSettingsOpen);

    // Click handler for "Save" button for setting page
    settingsSaveButton.addEventListener('click', () => {
      slot.names = nameListTextArea.value
        ? nameListTextArea.value.split(/\n/).filter((name) => Boolean(name.trim()))
        : [];
      slot.shouldRemoveWinnerFromNameList = removeNameFromListCheckbox.checked;
      slot.setNumberGroupWinner = numberGroupWinnerRef.current.value;

      soundEffects.mute = !enableSoundCheckbox.checked;
      // console.log('nameListTextArea.length', nameListTextArea.value.split(/\n/).length);
      onSettingsClose();
    });

    // Click handler for "Discard and close" button for setting page
    settingsCloseButton.addEventListener('click', onSettingsClose);
  }, []);

  const handleClick = (): void => {
    setPopup(false);
  };

  return (
    <div id="chinese-new-year">
      <Helmet>
        <title>SCB TechX Lucky Draw</title>
      </Helmet>
      {popup && (
        <div id="popup">
          <button className="top-right" onClick={handleClick}></button>
          <div className="centered" style={{ color: 'black' }}>
            {winner && (
              <div className="" id={`winner-${winner.length}`}>
                {winner.map((data: any, index: number) => (
                  <div key={`${data}-${index}`}>{data}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <canvas className="confetti" id="confetti-canvas"></canvas>

      <div className={`theme--red ${popup === true ? 'popup' : ''}`} id="app">
        <div className="main">
          <div className="snowflakes" aria-hidden="true">
            <div className="snowflake c1">
              <img src={ConfettiImg} alt="confetti" />
            </div>
            <div className="snowflake c2">
              <img src={ConfettiImg} alt="settings" />
            </div>

            <div className="snowflake c3">
              <img src={ConfettiImg} alt="settings" />
            </div>

            <div className="snowflake c4">
              <img src={ConfettiImg} alt="settings" />
            </div>

            <div className="snowflake c5">
              <img src={ConfettiImg} alt="settings" />
            </div>

            <div className="snowflake c6">
              <img src={ConfettiImg} alt="settings" />
            </div>

            <div className="snowflake c7">
              <img src={ConfettiImg} alt="settings" />
            </div>
            <div className="snowflake c8">
              <img src={ConfettiImg} alt="settings" />
            </div>
            <div className="snowflake c9">
              <img src={ConfettiImg} alt="settings" />
            </div>
            <div className="snowflake c10">
              <img src={ConfettiImg} alt="settings" />
            </div>
          </div>
          <div className="control-left">
            <img id="logo-techx" src={techx} alt="logo" />
            <div className="group-text">
              <div className="remaining-text">
                {remain > 0 && (
                  <span>
                    {remain}
                    <span className="triangle"></span>
                    <p className="challengers-text">PARTICIPANTS LEFT</p>
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="control-group">
            <button className="icon-button icon-button--small" id="settings-button">
              <img src={settings} alt="settings" />
            </button>
          </div>
          <div className="control-right-lantern-small">
            <img id="lantern-right-small" src={lanternRightSmall} alt="logo" />
          </div>

          <div id="lucky-draw">
            <img src={logo} alt="logo" />
            <div className="slot">
              <div className="slot__outer">
                <div className="slot__inner">
                  <div className="slot__shadow"></div>
                  <div className="reel" id="reel"></div>
                </div>
              </div>
              <div className="sunburst" id="sunburst">
                <img src={sunburst} alt="sunburst" />
              </div>
            </div>
            <div className="btn-spin">
              <img className="spin" src={spin} alt="spin" id="draw-button" />
              <img className="spin-hover" src={spinHover} alt="spin-hover" id="draw-button-hover" />
            </div>
          </div>
          <div className="settings" id="settings">
            <div className="settings__panel" id="settings-panel">
              <div className="settings__panel__group">
                <div className="group-header">
                  <h1 className="settings__title">Settings</h1>
                  <button className="solid-button solid-button" id="settings-save">
                    Save
                  </button>
                </div>
                <h2 className="prizes-text">Prizes</h2>
                <select
                  className="styled-option"
                  name="optionDate"
                  ref={numberGroupWinnerRef}
                  value={numberGroupWinner}
                  onChange={(e) => setNumberGroupWinner(parseInt(e.target.value))}
                >
                  <option key={`1`} value={1}>
                    1
                  </option>
                  <option key={`2`} value={2}>
                    2
                  </option>

                  <option key={`3`} value={3}>
                    3
                  </option>

                  <option key={`4`} value={4}>
                    4
                  </option>
                  <option key={`5`} value={5}>
                    5
                  </option>
                  <option key={`10`} value={10}>
                    10
                  </option>
                  <option key={`11`} value={11}>
                    11
                  </option>
                </select>

                <div className="input-group">
                  <div className="group-challengers">
                    <label className="input-label" htmlFor="name-list" id="challengers-text">
                      Challengers
                    </label>
                    <div id="remaining-text" style={{ color: 'white' }}>
                      {remain > 0 && <h3>{remain} Left</h3>}
                    </div>
                  </div>
                  <textarea
                    className="input-field input-field--textarea"
                    rows={8}
                    placeholder="Separate each name by line break"
                    id="name-list"
                  ></textarea>
                </div>
                <div className="input-group input-group--2-column">
                  <label className="input-label" htmlFor="remove-from-list">
                    Remove winner from list
                  </label>
                  <label className="input--switch">
                    <input type="checkbox" id="remove-from-list" />

                    <span className="slider"></span>
                  </label>
                </div>
                <div className="input-group input-group--2-column">
                  <label className="input-label" htmlFor="enable-sound">
                    Enable sound effect
                  </label>
                  <label className="input--switch">
                    <input type="checkbox" id="enable-sound" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="settings__panel__group">
                <button className="solid-button solid-button--danger" id="settings-close">
                  Discard and close
                </button>
              </div>
            </div>
          </div>
          <div className="control-footer">
            <img id="bg" src={lanternRight} alt="bg-center-wide" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TX2years;
